<template>
  <div class="body_wrap">

<!-- backtotop - start -->
<div class="backtotop">
  <a href="#" class="scroll">
    <i class="far fa-arrow-up"></i>
  </a>
</div>
<!-- backtotop - end -->

<!-- preloader - start -->
<!-- <div id="preloader"></div> -->
<!-- preloader - end -->

<!-- header_section - start
================================================== -->
      <header class="header_section">
        <div class="header_middle">
          <div class="container">
            <div class="row align-items-center">
              <div class="col col-lg-3 col-5">
                <div class="brand_logo">
                  <a class="brand_link" href="/">
                    <img src="./assets/logo.png">
                  </a>
                </div>
              </div>
              <div class="col col-lg-6 col-2">
                <nav class="main_menu navbar navbar-expand-lg">
                  <div class="main_menu_inner collapse navbar-collapse justify-content-center" id="main_menu_dropdown">
                    <ul class="main_menu_list ul_li">
                      <!-- <li class="active">
                        <a href="/">Home</a>
                      </li> -->
                      <!-- <li>
                        <a href="/register">register</a>
                      </li> -->
                      <!-- <li><a href="/register">Sign In</a></li>
                      <li><a href="/cart">Shopping Cart</a></li> -->
                      <li><a href="/#1">HOT ITEMS</a></li>
                      <li><a href="/#2">NEW ARRIVALS</a></li>
                      <li><a href="/#3">BEST SELLER</a></li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div class="col col-lg-3 col-5">
                <ul class="header_icons_group ul_li_right">
                  <li>
                    <button class="mobile_menu_btn navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_menu_dropdown" aria-controls="main_menu_dropdown" aria-expanded="false" aria-label="Toggle navigation">
                      <i class="fal fa-bars"></i>
                    </button>
                  </li>
                  <li><a href="/cart"><i class="fa-thin fa-cart-shopping"></i></a></li>
                  <li><a href="/account"><i class="fa-regular fa-user"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="header_bottom">
          <div class="container">
            <div class="row align-items-center" style="padding:10px 0px;">
              <div class="col col-md-10 col-10">
                <div class="allcategories_dropdown">
                  <h2 class="title_text" style="color:#fff;"><i class="fa-duotone fa-fire"></i> Technology e-commerce, worry-free shopping!</h2>
                </div>
              </div>

              <div class="col col-md-2 col-2">
                <!-- <a href="/cart">
                <button type="button" class="cart_btn">
                  <span class="cart_icon">
                    <i class="icon icon-ShoppingCart"></i>
                  </span>
                </button>
              </a> -->
              </div>
            </div>
          </div>
        </div>
      </header>
<main>
  <div class="sidebar-menu-wrapper">
    <div class="cart_sidebar">
      <button type="button" class="close_btn"><i class="fal fa-times"></i></button>

      <ul class="cart_items_list ul_li_block mb_30 clearfix">
        <li>
          <div class="item_image">
            <img src="assets/images/cart/cart_img_1.jpg" alt="Getyootech - Gadgets Ecommerce Site Template">
          </div>
          <div class="item_content">
            <h4 class="item_title">Yellow Blouse</h4>
            <span class="item_price">MXN$30.00</span>
          </div>
          <button type="button" class="remove_btn"><i class="fal fa-trash-alt"></i></button>
        </li>
        <li>
          <div class="item_image">
            <img src="assets/images/cart/cart_img_2.jpg" alt="Getyootech - Gadgets Ecommerce Site Template">
          </div>
          <div class="item_content">
            <h4 class="item_title">Yellow Blouse</h4>
            <span class="item_price">MXN$30.00</span>
          </div>
          <button type="button" class="remove_btn"><i class="fal fa-trash-alt"></i></button>
        </li>
        <li>
          <div class="item_image">
            <img src="assets/images/cart/cart_img_3.jpg" alt="Getyootech - Gadgets Ecommerce Site Template">
          </div>
          <div class="item_content">
            <h4 class="item_title">Yellow Blouse</h4>
            <span class="item_price">MXN$30.00</span>
          </div>
          <button type="button" class="remove_btn"><i class="fal fa-trash-alt"></i></button>
        </li>
      </ul>

      <ul class="total_price ul_li_block mb_30 clearfix">
        <li>
          <span>Subtotal:</span>
          <span>MXN$90</span>
        </li>
        <li>
          <span>Vat 5%:</span>
          <span>MXN$4.5</span>
        </li>
        <li>
          <span>Discount 20%:</span>
          <span>- MXN$18.9</span>
        </li>
        <li>
          <span>Total:</span>
          <span>MXN$75.6</span>
        </li>
      </ul>

      <ul class="btns_group ul_li_block clearfix">
        <li><a class="btn btn_primary" href="/cart">View Cart</a></li>
        <li><a class="btn btn_secondary" href="checkout.html">Checkout</a></li>
      </ul>
    </div>

    <div class="cart_overlay"></div>
  </div>
  <div class="breadcrumb_section">
    <div class="container">
      <ul class="breadcrumb_nav ul_li">
        <li><a href="/">Home</a></li>
        <li>My Account</li>
      </ul>
    </div>
  </div>
  <section class="account_section section_space">
    <div class="container">
      <div class="row">
        <div class="col col-lg-3">
          <div class="account_menu">
            <h2 class="title_text">My Account</h2>
            <ul class="account_menu_list ul_li_block">
              <li :class="stop === 1?'active':''"><a @click="stop = 1">Account Dashboard</a></li>
              <li :class="stop === 4?'active':''"><a @click="stop = 4">personal information</a></li>
              <li :class="stop === 2?'active':''"><a @click="stop = 2">Balance Withdraw</a></li>
              <li :class="stop === 3?'active':''"><a @click="stop = 3">Balance recharge</a></li>
              <li :class="stop === 5?'active':''"><a @click="stop = 5,tixianjilu()">Order History</a></li>
              <li :class="stop === 6?'active':''"><a @click="stop = 6,tixian()">Withdrawal History</a></li>
            </ul>
          </div>
        </div>

        <div class="col col-lg-9" v-if="stop === 1">
          <div class="account_content_area">
            <h3>My Dashboard</h3>
            <ul class="content_layout ul_li_block">
              <li>
                <h4><strong>Hello, {{ userData.nickname }}</strong></h4>
                <p class="mb-0">
                  From your My Account Dashboard you have the ability to view a snapshot of your recent account activity and update.
                </p>
              </li>
              <li>
                <p class="mb-0">
                  Clabe account: {{ userData.clabe }}
                </p>
              </li>
              <li>
                Current total funds: MXN${{ amountData.nowMoney }}
              </li>
              <li>
                Cumulative recharge: MXN${{ amountData.recharge }}
              </li>
              <li>
                accumulative consumption: MXN${{ amountData.orderStatusSum }}
              </li>
            </ul>
          </div>
          <button style="margin-top:30px;" type="submit" @click="SignOut()" class="btn btn_danger">Sign Out</button>
        </div>
        <div class="col col-lg-9" v-if="stop === 2">
          <div class="account_content_area">
            <h3>Balance Withdraw</h3>
            <ul class="content_layout ul_li_block">
              <li>
                <div class="form_item_wrap">
                  <div class="form_item" style="display: flex;align-items: center;margin-bottom: 0px;">
                    <div style="width:150px;">clabe Account</div><input style="width: 75%;margin-left: 20px;" v-model="Withdraw.clabe" id="username_input" type="text" name="username" placeholder="clabe Account">
                  </div>
                </div>
              </li>
              <li>
                <div class="form_item_wrap">
                  <div class="form_item" style="display: flex;align-items: center;margin-bottom: 0px;">
                    <div style="width:150px;">Withdrawal Amount</div><input style="width: 75%;margin-left: 20px;" v-model="Withdraw.money" id="username_input" type="text" name="username" placeholder="Withdrawal Amount">
                  </div>
                </div>
              </li>
              <li>
                <div class="form_item_wrap">
                  <div class="form_item" style="display: flex;align-items: center;margin-bottom: 0px;">
                    <div style="width:150px;">remark</div><input style="width: 75%;margin-left: 20px;" v-model="Withdraw.mark" id="username_input" type="text" name="username" placeholder="remark">
                  </div>
                </div>
              </li>
            </ul>
            <a class="btn btn_primary" style="color: #fff;margin-top:30px;" @click="zhuanzhang()" data-animation="fadeInUp2" data-delay=".8s">Confirm Transfer</a>
          </div>
        </div>
        <div class="col col-lg-9" v-if="stop === 3">
          <div class="account_content_area">
            <h3>Balance recharge</h3>
            <ul class="content_layout ul_li_block">
              <li>
                <div class="form_item_wrap">
                  <div class="form_item" style="display: flex;align-items: center;margin-bottom: 0px;">
                    <div style="width:130px;">Recharge Amount</div><input style="width: 75%;margin-left: 20px;" v-model="recharge.price" id="username_input" type="text" name="username" placeholder="Recharge Amount">
                  </div>
                </div>
              </li>
            </ul>
            <a class="btn btn_primary" style="color: #fff;margin-top:30px;" @click="chongzhi()" data-animation="fadeInUp2" data-delay=".8s">Confirm Recharge</a>
          </div>
        </div>
        <div class="col col-lg-9" v-if="stop === 4">
          <div class="account_content_area">
            <h3>Modify personal information</h3>
            <ul class="content_layout ul_li_block">
              <li>
                <div class="form_item_wrap">
                  <div class="form_item" style="display: flex;align-items: center;margin-bottom: 0px;">
                    <div style="width:130px;">clabe Account</div><input style="width: 75%;margin-left: 20px;" v-model="editData.clabe" type="text" name="username" placeholder="clabe Account">
                  </div>
                </div>
              </li>
              <li>
                <div class="form_item_wrap">
                  <div class="form_item" style="display: flex;align-items: center;margin-bottom: 0px;">
                    <div style="width:130px;">Username</div><input style="width: 75%;margin-left: 20px;" v-model="editData.nickname" type="text" name="username" placeholder="Username">
                  </div>
                </div>
              </li>
              <li>
                <div class="form_item_wrap">
                  <div class="form_item" style="display: flex;align-items: center;margin-bottom: 0px;">
                    <div style="width:130px;">Phone Number</div><input style="width: 75%;margin-left: 20px;" v-model="editData.phone" type="text" name="username" placeholder="Phone Number">
                  </div>
                </div>
              </li>
              <li>
                <div class="form_item_wrap">
                  <div class="form_item" style="display: flex;align-items: center;margin-bottom: 0px;">
                    <div style="width:130px;">Real Name</div><input style="width: 62%;margin-left: 20px;" v-model="editData.realName" type="text" name="username" placeholder="Real Name">
                  </div>
                </div>
              </li>
            </ul>
            <a class="btn btn_primary" style="color: #fff;margin-top:30px;" @click="xiugai()" data-animation="fadeInUp2" data-delay=".8s">Modify Information</a>
          </div>
        </div>
        <div class="col col-lg-9" v-if="stop === 5">
          <div class="account_content_area">
            <h3>Order History</h3>
            <div class="cart_table">
              <table class="table">
                <thead>
                  <tr>
                    <th style="text-align: left;">PRODUCT</th>
                    <th class="text-center">PRICE</th>
                    <th class="text-center">QUANTITY</th>
                    <th class="text-center">TOTLA</th>
                  </tr>
                </thead>
                <tbody v-if="dingdanData.list">
                  <tr v-for="(item, index) in dingdanData.list" :key="index">
                    <td>
                      <div class="cart_product">
                        <!-- <img v-if="item.orderInfoList[0].productId === 1" src="assets/images/shop/product_img_1.png" alt="Getyootech - Gadgets Ecommerce Site Template">
                        <img v-if="item.orderInfoList[0].productId === 2" src="assets/images/shop/product_img_4.png" alt="Getyootech - Gadgets Ecommerce Site Template">
                        <img v-if="item.orderInfoList[0].productId === 3" src="assets/images/shop/product_img_2.png" alt="Getyootech - Gadgets Ecommerce Site Template">
                        <img v-if="item.orderInfoList[0].productId === 4" src="assets/images/shop/product_img_5.png" alt="Getyootech - Gadgets Ecommerce Site Template">
                        <img v-if="item.orderInfoList[0].productId === 5" src="assets/images/shop/product_img_3.png" alt="Getyootech - Gadgets Ecommerce Site Template">
                        <img v-if="item.orderInfoList[0].productId === 6" src="assets/images/shop/product_img_6.png" alt="Getyootech - Gadgets Ecommerce Site Template">
                        <img v-if="item.orderInfoList[0].productId === 8" src="assets/images/shop/product_img_7.png" alt="Getyootech - Gadgets Ecommerce Site Template"> -->
                        <img style="margin:auto;" :src="'assets/images/' + item.orderInfoList[0].image" alt="Getyootech - Gadgets Ecommerce Site Template">
                      </div>
                    </td>
                    <td class="text-center"><span class="price_text">MXN${{ item.orderInfoList[0].price }}</span></td>
                    <td class="text-center">
                      <form action="#">
                        <div class="quantity_input">
                          <div style="margin:0px 10px;">{{ item.orderInfoList[0].cartNum }}</div>
                        </div>
                      </form>
                    </td>
                    <td class="text-center"><span class="price_text">MXN${{ item.orderInfoList[0].price }}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col col-lg-9" v-if="stop === 6">
          <div class="account_content_area">
            <h3>Withdrawal History</h3>
            <div class="cart_table">
              <table class="table">
                <thead>
                  <tr>
                    <th style="text-align: left;">amount</th>
                    <th style="text-align: left;">Withdrawal Account</th>
                    <th class="text-center">Status</th>
                    <th class="text-center">Creation Time</th>
                    <th class="text-center">Withdrawal Type</th>
                    <th class="text-center">Remark</th>
                  </tr>
                </thead>
                <tbody v-if="tixianData.list">
                  <tr v-for="(item, index) in tixianData.list" :key="index">
                    <td>
                      <span class="price_text">MXN${{ item.extractPrice }}</span>
                    </td>
                    <td class="text-center"><span class="price_text">{{ item.clabe }}</span></td>
                    <td class="text-center">
                      <span class="price_text" v-if="item.status === -1">Rejected</span>
                      <span class="price_text" v-if="item.status === 0">Pending Approval</span>
                      <span class="price_text" v-if="item.status === 1">Withdrawn</span>
                    </td>
                    <td class="text-center">
                      <span>{{ item.createTime }}</span>
                    </td>
                    <td class="text-center"><span class="price_text">{{ item.extractType }}</span></td>
                    <td class="text-center"><span class="price_text">{{ item.mark }}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="newsletter_section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col col-lg-6">
          <h2 class="newsletter_title text-white">
            <i class="icofont-paper-plane"></i>
            Sign Up for Newsletter
          </h2>
        </div>
        <div class="col col-lg-6">
          <form action="#!">
            <div class="newsletter_form">
              <input type="email" name="email" placeholder="Enter your email address">
              <button type="submit" class="btn btn_danger">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</main>
<footer class="footer_section">
        <div class="footer_widget_area">
          <div class="container">
            <div class="row">
              <div class="col col-lg-2 col-md-3 col-sm-6">
                <div class="footer_widget footer_useful_links">
                  <h3 class="footer_widget_title text-uppercase">Quick Links</h3>
                  <ul class="ul_li_block">
                    <li><a href="/register">Sign In</a></li>
                    <!-- <li><a href="/register">Sign Up</a></li> -->
                  </ul>
                </div>
              </div>

              <div class="col col-lg-2 col-md-3 col-sm-6">
                <div class="footer_widget footer_useful_links">
                  <h3 class="footer_widget_title text-uppercase">Custom area</h3>
                  <ul class="ul_li_block">
                    <li><a href="/account">My Account</a></li>
                    <li><a href="/cart">My Cart</a></li>
                  </ul>
                </div>
              </div>

              <div class="col col-lg-8 col-md-6 col-sm-12">
                <div class="footer_widget footer_useful_links">
                  <h3 class="footer_widget_title text-uppercase">contact us</h3>
                  <ul class="ul_li_block">
                    <li>Company Name： INFORMATICA GH SOLUCIONES, S.A. DE C.V.</li>
                    <li>E-mail: hungrybulo@outlook.com</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
</div>
</template>

<script>
import {
  apiFrontUserBalance, apiFrontUser, apiFrontExtractClabeCash, apiFrontRechargeClabe, apiFrontUserEdit,
  apiFrontOrderList, apiFrontExtractClabeRecord
} from '@/api/goods'
export default {
  name: 'account',
  data() {
    return {
      userData: {},
      amountData: {},
      stop: 1,
      Withdraw: {
        money: '',
        clabe: '',
        mark: ''
      },
      recharge: {
        from: 'WOWPay',
        payType: 'clabe',
        price: '',
        groupDataId: '0',
        rechar_id: '0'
      },
      editData: {
        avatar: '',
        clabe: '',
        nickname: '',
        phone: '',
        realName: '',
      },
      tixianjiluData: {},
      dingdanData: {},
      tixianData: {}
    }
  },
  created() {
    this.init()
    apiFrontUserBalance().then(res => {
      this.amountData = res.data
    })
    console.log(this.$route.query.record);
    if (this.$route.query.record) {
      this.stop = parseInt(this.$route.query.record)
    }
    
  },
  methods: {
    init() {
      apiFrontUser().then(res => {
        if (res.data.clabe) {
          this.Withdraw.clabe = res.data.clabe
        }
        
        this.editData.avatar = res.data.avatar
        this.editData.clabe = res.data.clabe
        this.editData.nickname = res.data.nickname
        this.editData.phone = res.data.phone
        this.editData.realName = res.data.realName
        this.userData = res.data
      }).catch((err) => {
        console.log(err);
      })
    },
    zhuanzhang() {
      apiFrontExtractClabeCash(this.Withdraw).then(res => {
        console.log(res)
        this.$message.success('Successful withdrawal')
      }).catch((err) => {
        this.$message.error(err)
      })
    },
    chongzhi() {
      apiFrontRechargeClabe(this.recharge).then(res => {
        console.log(res)
        if (res.data.status) {
          window.open(res.data.wowPayCheckout, '_blank');
        } else {
          this.$message.error('Failed to Retrieve Checkout Link')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('Failed to Retrieve Checkout Link')
      })
    },
    xiugai() {
      apiFrontUserEdit(this.editData).then(res => {
        this.stop = 1
        this.$message.success('modify successfully')
        this.init()
      })
    },
    tixianjilu() {
      // apiFrontPayQueryExtractDisburseResult().then(res => {
      //   this.tixianjiluData = res.data
      // })
      var data = {
        type: '1',
        limit: 1000,
        page: 1,
      }
      apiFrontOrderList(data).then(res => {
        this.dingdanData = res.data
      })
    },
    tixian() {
      var data = {
        limit: 1000,
        page: 1
      }
      apiFrontExtractClabeRecord(data).then(res => {
        console.log(res);
        this.tixianData = res.data
      })
    },
    SignOut() {
      localStorage.removeItem('token');
      this.$router.push('/')
    }
  }
}
</script>

<style>

</style>